$breakpoint-mobile: 998px;

.LJ-mainmenu {
  z-index: 100000000;
  overflow: initial !important;
}

[id^="slice"] {
  cursor: pointer;
  scale: 1;

  &:hover {
    scale: 1.01;
    opacity: 0.9;
  }

  &:has(.selected) {
    scale: 1.02;
  }

  -webkit-transition: opacity, scale 0.3s ease-out;
  -moz-transition: opacity, scale 0.3s ease-out;
  -ms-transition: opacity, scale 0.3s ease-out;
  -o-transition: opacity, scale 0.3s ease-out;
  transition: opacity, scale 0.3s ease-out;
}

#center {
  > div {
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: contain;
    font-size: 1.2vw;
    font-weight: bold;

    /* &:after {
      content: "";
      width: 72%;
      height: 72%;
      position: absolute;
      border: solid 2px white;
      border-radius: 100%;
    }*/
    &:hover {
      opacity: 0.9;
      scale: 1.01;
    }

    &.selected {
      scale: 1.02;
    }

    ul {
      text-align: center;
      padding: 0;
      list-style: none;
      margin-top: 10px;

      li {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }
}

.scrollbar-container {
  .ps__rail-x,
  .ps__rail-y {
    z-index: 10000000;
  }
}

@media (min-width: $breakpoint-mobile) {
  #center {
    > div {
      font-size: 1.4vw;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  #center {
    > div {
      font-size: 3.4vw;
    }
  }
}
