@import "../../styles/common.scss";

.LJ_Toolbar {
  background: $primaryColor;
  color: white;
  display: flex;
  .LJ_logo {
    padding: 16px;
    display: flex;
    align-items: center;
    width: 22vw;
    max-width: 190px;
    img {
      width: 100%;
    }
  }
  & .MuiIconButton-root {
    color: inherit;
  }
  .leftSection,
  .rightSection {
    display: flex;
    align-items: center;
  }
  .rightSection {
    margin-left: auto;
    & a,
    & .MuiIconButton-root {
      color: white;
      padding: 16px;
      & div {
        display: flex;
        align-items: center;
      }
    }
    & .profileItem {
      & .MuiTypography-root {
        color: white;
      }
    }
  }
}
