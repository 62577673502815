@keyframes breathe {
    50% {
        transform: scale(1);
        opacity: 0.2;
    }

    100% {
        transform: scale(0.2);
        opacity: 0.1;
    }
}

#animBGWrapper {
    background: url("./vda_lj_bg.jpg");
    width: 100%;
    height: 100%;
    overflow: hidden;
    filter: blur(5px);
    position: fixed;
    top: 0;
    z-index: -1;
    user-select: none;
    pointer-events: none;
}

.animBGBlock {
    display: inline-flex;
    flex-wrap: wrap;
    /*animation: breathe 10s infinite;*/
    transform: scale(0.2);
    transition: 200ms;
    opacity: 0;
}